import beperkingpicto from './Beperking.png';


const beperking = () =>{
return <div className="content">

<div className="article3">

<img src={beperkingpicto} alt="Drsnl logo" width="50%" height="40%"/><br/><br/>

<section>
<br/><br />
<h2>Mensen met beperking</h2>
<p align="left">
Het DRS, Systeem Diversiteit Sterren ( duwen van onderaf in de samenleving 
via de maatschappelijke pressie-effecten goodwill,koopKRACHT en specifiek 
overheidsbeleid ) , zal vooral voor die diversiteitsgroepen van belang zijn, 
die nu al te lang zeer onderbedeeld zijn wat betreft hun deelname op de arbedsmarkt. 
Natuurlijk is dat het gevolg van het ontbreken van verschillende speciale 
voorzieningen voor deze groep. Maar zeer veel invloed op hun aanstelling zal ook 
bereikt worden door de pressie vanuit de samenleving als gevolg van dit DRS, 
Systeem Diversiteit Sterren voor instellingen en bedrijven. Aan het aantal volledig
 ingekleurde sterren kan een ieder via de website ( straks in een oogopslag ) 
 zien welke werkgevers voldoen en welke niet voldoen aan eerlijk delen 
 ( tussen deze diversiteitsgroepen )  in hun personeelsbestand. De drie hierboven 
 genoemde publieke maatschappelijke pressie-effecten zullen werkgevers ervan overtuigen 
 dat zij ook wat deze groep mensen ( met een beperking ) betreft de gesteld beleidsdoelen 
 tijdig zullen moeten realiseren, omdat inclusie ook hier een absolute voorwaarde is 
 voor eerlijk delen.
 </p>
 <br /><br />
</section>
<br /><br />



<section>
<p>
Meer weten?<br />
E-mail naar:<br />
info@drsnl.nl<br /><br />
</p>
</section>
</div>
</div>
}
export default beperking;
import { useMediaQuery } from "react-responsive";
import { Outlet, Link } from "react-router-dom";
import React, { useState } from 'react';
import logo2 from './header people klein2.gif';


const Layout = () => {
	<meta name="viewport" content="width=device-width, initial-scale=1.0"/> 
	
	const [width, setWindowWidth] = useState(0);
  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }
	
  return (
  
  
  
  
  
    <>
	
	
    <div className="Home">
      <header className="App-header">
	
     
    </header>
    </div>

    <nav>
        <>
		<div className="header">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
           <Link to="/motivatieen">Motivatie & inspiratie</Link>
          </li>
          <li>
           <Link to="/vrouwen">Vrouwen</Link>
          </li>
		  <li>
            <Link to="/beperking">Mensen met beperking</Link>
          </li>	
			<li>
            <Link to="/etnisch">Etnische groepen</Link>
          </li>
		  <li>
           <Link to="/lhbt">LHBTIQ+</Link>
          
          
          </li>	
		  <li>
            <Link to="/contact">Contact</Link>
          </li>
		 </div>
        </>
        <br />
    <div className="constructie">
    De website is nog in constructie
    </div><br />
        <img src={logo2} alt="logo2" width="72.5%" height="70%"/>

      </nav>
    <Outlet />
   
    </>
    
  )
};

export default Layout;
const Contacts = () => {
  return <div className="content">
 

<div className="article3">
<section>
<h1>Contact</h1>
Meer weten?<br />
E-mail naar:<br />
info@drsnl.nl<br /><br />
<br /><br />
</section>
</div>
 </div>
};

export default Contacts;
import ReactDOM from "react-dom/client";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Etnisch from "./pages/Etnisch";
import Beperking from "./pages/Beperking";
import LHBT from "./pages/Lhbt";
import Vrouwen from "./pages/Vrouwen";
import Motivatieen from "./pages/Motivatieen";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import "./pages/index.css";


export default function App() {
  return (
  <div>
  <center>
    <BrowserRouter>
      <Routes>
		   <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="Motivatieen" element={<Motivatieen />} />
          <Route path="etnisch" element={<Etnisch />} />
		  <Route path="Beperking" element={<Beperking />} />
		   <Route path="lhbt" element={<LHBT />} />
		  <Route path="Vrouwen" element={<Vrouwen />} />
		  <Route path="contact" element={<Contact />} />
		  <Route path="*" element={<NoPage />} />	
		  


		  
        </Route>
		</Routes>
    </BrowserRouter>
	
	</center>
	</div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
const Motivatieen = () =>{
return <div className="content">

<div className="article3">


<section>
<h2>Motivatie & inspiratie</h2>
<p align="left">
Als bedenker van dit DRS , Systeem Diversiteit Sterren voor bedrijven en instellingen, 
wil ik u graag deelgenoot maken van mijn ervaringen als jurist, politicus en coordinator 
beleid etnische groepen.         
In het bijzonder wat betreft de positie van de verschillende diversiteitsgroepen op de 
Nederlandse arbeidsmarkt. Daarbij heb ik in het bijzonder gekeken naar de wijze waarop en
 het tempo waarin de vrouwenemancipatie zich heeft ontwikkeld sinds de jaren zestig van 
 de vorige eeuw. Nu meer dan 60 jaren later moeten wij helaas constateren, dat er voor 
 vrouwen nog steeds geen sprake is van een eerlijke verdeling van het werk in alle sectoren 
 en op alle niveaus op de Nederlandse arbeidsmarkt. <br /><br />
Daarom moeten wij nu al concluderen, dat de andere diversiteitsgroepen veel sneller en 
gerichter moeten worden geleid en begeleid naar hun evenredige deelname op deze arbeidsmarkt.
Geïnspireerd door de welbekende “Michelin Sterren” en de stimulerende effecten , die 
kunnen uitgaan van het in politiek-bestuurlijke kringen eveneens bekende “Bonus-Malus 
Systeem” heb ik dit DRS , Diversity Rating System, Systeem Diversiteit Sterren, bedacht, 
zoals bij de websitelogo wordt geschetst. <br /><br />
Via drie, van deze diversiteit sterren uitgaande, maatschappelijke pressie-effecten 
namelijk Goodwill, KoopKRACHT en Specifiek Overheidsbeleid, kan worden bereikt dat op een 
relatief korte termijn ( 1 tot 2 decennia ) kan worden gekomen tot een veel eerlijker 
verdeling van werk op de Nederlandsse arbeidsmarkt.                
Het specifiek overheidsbeleid houdt in dat de regering moet besluiten dat op een nog nader
 te bepalen termijn ( bijvoorbeeld 2035, 2040 of 2050 ) alleen bedrijven en instellingen 
 met drie volledig ingekleurde sterren in aanmerking komen voor bepaalde overheidsopdrachten
  en – faciliteiten o.m. ook belastingfaciliteiten.<br /><br /> 
Dan kunnen bedrijven en instellingen in ieder geval niet meer beweren dat zij onvoldoende
 voorbereidingstijd hebben gehad of andere dooddoeners debiteren, zoals zij bij de 
 vrouwenemancipatie in ruime mate hebben gedaan en nog steeds doen ( voor de top van 
 beursgenoteerde bedrijven is daarom inmiddels quotering ingevoerd ).<br /><br />
Op een aparte website, bijvoorbeeld “Diversiteit sterren.nl” , kan straks iedereen direct 
en gemakkelijk zien of en zo ja in welke mate een bedrijf of instelling 
( in het personeelsbestand ) voldoet aan eerlijk delen op alle niveaus tussen vrouwen, 
mensen met een beperking, etnische groepen en lhbtiq+ ers. Burgers ( goodwill en koopKRACHT )
en overheden ( specifiek overheidsbeleid ) kunnen hun waardering voor die instellingen en
bedrijven daarop afstemmen. <br /><br />

Sociaal en Cultureel Planbureau
Het Sociaal en Cultureel Planbureau heeft een afdeling Methodologie. 
De regering zou aan deze afdeling kunnen vragen om dit DRS Diversiteit Sterren Systeem 
nader uit te werken en operationeel te maken bijvoorbeeld ten behoeve van een proefproject 
in een  representatief deel van het land.
</p>
 <br /><br />
</section>
<br /><be />



<section>
<p>
Meer weten?<br />
E-mail naar:<br />
info@drsnl.nl<br /><br />
</p>
</section>
</div>
</div>

}
export default Motivatieen;
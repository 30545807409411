import etnischpicto from './Etnische groepen.png';



const Etnisch = () =>{
return <div className="content">

<div className="article3"> 
<img src={etnischpicto} alt="Etnische pictogram" width="50%" height="40%"/><br/><br/>
<section>
<h2>Etnische groepen</h2>
<p align="left">
De deelneming aan de arbeidsmarkt door niet westerse mannen en vrouwen 
blijft ver achter bij die van autochtone mannen en vrouwen. Hoewel er 
sprake is van een zekere groei in de deelname van niet westerse personen 
op de arbeidsmarkt, is die groei beduidend minder dan die van autochtonen. 
Er zal dus voor de komende jaren sprake moeten zijn van intensivering van 
deze groei. De tot nu toe gekozen beleidsinspanningen daartoe gaan nog 
steeds uit van “zelfregulering door werkgevers”. De afgelopen decennia 
laten zien dat die beleidsinspanningen onvoldoende resultaat opleveren. 
Invoering van het DRS Systeem, Diversiteit Sterren, kan door de daaruit 
voortvloeiende publieke maatschappelijke pressie ( goodwill, koopKRACHT 
en specifiek overheidsbeleid ) leiden tot een meer intensieve groei in 
de deelneming aan de arbeidsmarkt door allchtone mannen en vrouwen. 
Uiteindelijk uitkomend op eerlijk delen op de arbeidsmarkt op alle niveaus 
in het personeelsbestand.<br /><br /></p>
</section>
<br /><br />

<section>
<p>
Meer weten?<br />
E-mail naar:<br />
info@drsnl.nl<br /><br />
</p>
</section>
</div>
</div>


}
export default Etnisch;
import lhbtpicto from './lhbt.png';

const Lhbt = () =>{
return <div className="content">

<div className="article3">

<img src={lhbtpicto} alt="Drsnl logo" width="40%" height="35%"/><br/><br/>
<section>
<h2>LHBTIQ+</h2>
<p align="left">
Wij moeten als samenleving, die eerlijk delen in alle opzichten wil praktiseren, 
naar een inclusieve werkvloer ook voor LHBTIQ+ werknemers. Seksuele diversiteit 
op de werkvloer is net zo nodig en nuttig als de diversiteit van andere groepen 
op diezelfde werkvloer. Nog steeds bestaan er relatief veel knelpunten op het 
gebied van in- en doorstroom binnen bedrijven en instellingen. In vooral recente 
literatuur en onderzoeksresultaten worden steeds meer aanbevelingen gedaan voor 
de aanpak van die knelpunten. Zo zijn er al veel lhbtiq+ medewerkersnetwerken 
binnen bedrijven, die het Charter Diversiteit hebben ondertekend. 
Om de in- en doorstroom van lhbtiq+ ers te intensiveren, is invoering van het DRS 
Systeem, Diversiteit Sterren , zeer aan te bevelen.                                
Onbekend maakt onbemind dus via de uit dit systeem voortvloeiende publieke 
maatschappelijke pressie ( goodwill, koopKRACHT en specifiek overheidsbeleid ) 
kunnen de diversiteit sterren ervoor zorgen dat zowel op de korte als op de lange 
termijn ook met lhbtiq+ ers het eerlijk delen is op de arbeidsmarkt.
</p>
<br /><br />
</section><br /><br />

<section>
<p>
Meer weten?<br />
E-mail naar:<br />
info@drsnl.nl<br /><br />
</p>
</section>
</div>
</div>



}
export default Lhbt;
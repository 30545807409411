import vrouwenpicto from './vrouwen.png';

const Vrouwen = () =>{
return <div className="content">

<div className="article3">

<img src={vrouwenpicto} alt="Drsnl logo" width="40%" height="35%"/><br/><br/>
<section>
<h2>Vrouwen</h2>
<p align="left">
De vrouwenemancipatie , die is begonnen in de jaren 60 van de vorige eeuw en die 
ook betrekking heeft op de arbeidsmarkt heeft zelfs nu , na meer dan 60 jaren , 
nog niet het gewenste resultaat opgeleverd. Per 1 januari 2023 is de Wet Ingroeiquotum 
en Streefcijfers ( Wet Vrouwenquotum ) ingevoerd via het trekken/duwen effect 
( trekken van bovenop ). Omdat er bij de grote Nederlandse bedrijven in de top nog 
steeds relatief weinig vrouwen werkzaam zijn . Het DRS , Systeem Diversiteit Sterren,  
is een gerichte, goede aanvulling en zorgt via de publieke maatschappelijke 
pressie-effecten ( goodwill , koopKRACHT en specifiek overheidsbeleid ) dat voor de 
eerder genoemde termijndoelen, zoals bij het klimaatbeleid, de totale samenleving wordt
gemobiliseerd ( duwen van onderaf ) om ook deze diversiteitsbeleidsdoelen ( eerlijk 
delen op de arbeidsmarkt ) te realiseren.
</p>
 <br /><br />
</section>
<br /><be />



<section>
<p>
Meer weten?<br />
E-mail naar:<br />
info@drsnl.nl<br /><br />
</p>
</section>
</div>
</div>

}
export default Vrouwen;
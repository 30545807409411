import React, { useState } from 'react';
import logodrsnl from './drsnllogo gangbaar onderin.png';
import devolledig from './de volledig ingekleurde sterren.png';
import personeela from './personeela.png';
import personeelb from './personeelb.png';
import personeelc from './personeelc.png';


import ReactDOM from 'react-dom/client';




const Home = () => {



   


//return <div style="position:relative; top: -50px; left:5px; color:red; font-size:3em; z-index:2">laag 2</div>



return <div className="content">





<div className="article3">
<meta name="viewport" content="width=device-width, initial-scale=1.0"/> 
<img src={logodrsnl} alt="Drsnl logo" width="45%" height="55%"/>





<section id="devierpictos"> 

      <h1>De volledig ingekleurde Diversiteit ster</h1>
      <img src={devolledig} alt="De volledig ingekleurde sterren" width="45%" height="55%"/><br ></br>
      <p>
      <p align="left">
      Deze volledig ingekleurde Diversiteit Ster geeft in één oogopslag aan 
      dat voldaan wordt aan eerlijk delen tussen vrouwen, mensen met een beperking, 
      etnische groepen en de lhbtiq+ gemeenschap. En als een bedrijf of instellng drie 
      verticaal opgestelde en volledig ingekleurde sterren heeft,dan betekent het dat 
      die organisatie op alle niveaus ( personeel hoog, laag en midden ) 
      een eerlijk divers personeelsbeleid heeft. Door specifiek overheidsbeleid, 
      zoals hierna omschreven, kan die situatie nationaal relatief snel worden bereikt, 
      om zodoende oneerlijke ontstane arbeidsmarktsituaties zo snel mogelijk weg te werken. 
      Hieronder volgen 3 praktijk voorbeelden<br /><br /></p>
        
        </p>

     </section>  


     <section id="personeela"> 

<h1>Voorbeeld A</h1>
<p>

<img src={personeela} alt="personeel a" width="45%" height="55%"/><br /><br />
<p align="left">    
Dit voorbeeld A toont de volledig ingekleurde drie sterren, die een 
bedrijf of instelling krijgt wanneer het personeelsbestand op alle 
niveaus ( laag, midden en hoog ) voldoet aan eerlijk delen tussen vrouwen, 
mensen met een beperking, etnische groepen en de lhbtiq+ ers. Dus de 
eindsituatie die wij als samenleving willen bereiken. Een samenleving 
waarin er sprake is van radicale gelijkwaardigheid.<br /><br /></p>  
</p>

</section>  

<section id="personeelb"> 

<h1>Voorbeeld B</h1>
<p>
<img src={personeelb} alt="personeel a" width="45%" height="55%"/><br /><br />
<p align="left">    
Dit voorbeeld B geeft duidelijk en in één oogopslag aan dat er bij 
dit bedrijf/deze instelling alleen op het lage en middenniveau 
sprake is van eerlijk delen van het werk. Immers alléén op het 
midden- en lage niveau in het personeelsbestand zijn de sterren 
volledig ingekleurd. Op het hoge niveau is de ster helemaal niet 
ingekleurd en dat betekent dat bijna alleen witte mannen daar goed 
vertegenwoordigd zijn. Deze situatie moet dus snel veranderen door 
specifiek overheisbeleid.<br /><br />
</p>
  
  </p>

</section>  

<section id="personeelc"> 

<h1>Voorbeeld C</h1>
<p>
<img src={personeelc} alt="personeel a" width="45%" height="55%"/><br /><br />
<p align="left">    
Voorbeeld C laat zien dat er alleen op het lage niveau in het 
personeelsbestand sprake is van een eerlijke verdeling in het 
personeelsbestand. Op het midden- en hoge niveau zijn de 
betreffende sterren daarom niet ingekleurd , hetgeen betekent 
dat de vier diversiteitsgroepen vrijwel niet vertegenwoordigd 
zijn in het personeelsbestand daar. Er moet dus versneld gewerkt 
worden aan de situatie zoals weergegeven in Voorbeeld A hierboven. 
Specifiek overheidsbeleid kan dat bevorderen.<br /><br />
</p>
</p>
</section>  

<section>
<p>
Meer weten?<br />
E-mail naar:<br />
info@drsnl.nl


</p>
</section>
</div>


</div>
};

export default Home;

